import React, { useState, useEffect } from "react";
import { Container, Row, Col, ButtonGroup, Button, Nav } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import ImageSvgs from "../../ImageSvgs";
import Loader from "../../Loader/Loader";
import ProcessViewContent from "./ProcessViewContent";
import { getData } from "../../../services/apiService";
import { getHostUrl } from "../../../config/environment";

import { UserModal } from "../../../utils/processUtil";
import { ProcessOrgDetailModal } from "./ProcessOrgDetailModal";
import ProcessFilterDropdown from "../ProcessUserPage/ProcessFilterDropdown";

export default function ProcessView() {
  const [activeBtn, setActiveBtn] = useState("cardView");
  const [processDetails, setProcessDetails] = useState([]);
  const [included, setIncluded] = useState([]);
  const [totalPage, setTotalPage] = useState();
  const [activePro, setActivePro] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [finalOrgQuery, setFinalOrgQuery] = useState();
  const [finalGrpQuery, setFinalGrpQuery] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [usersData, setUsersData] = useState([]);
  const [showEmpModal, setShowEmpModal] = useState(false);
  const [showOrgModal, setShowOrgModal] = useState(false);
  const [orgValues, setOrgValues] = useState([]);
  const [query, setQuery] = useState("");

  const [dropdownShow, setDropdownShow] = useState(false);
  const [groupQuery, setGroupQuery] = useState([]);
  const [orgQuery, setOrgQuery] = useState("");
  const [orgList, setOrgList] = useState([]);
  // const [allLoading, setAllLoading] = useState(false);

  const adminCheck = sessionStorage.getItem("adminCheck");

  const navigate = useNavigate();

  useEffect(() => {
    const fetchGroupData = async () => {
      try {
        const groupEndpoint = `${getHostUrl()}process/groups`;
        const grpResponse = await getData(groupEndpoint);
        if (grpResponse?.status === 200) {
          setGroupList(grpResponse.data.data);
        }
      } catch (error) {
        console.error(error);
      }
    };

    fetchGroupData();
  }, []);
  useEffect(() => {
    const fetchProcessData = async (page) => {
      setLoading(true);

      try {
        const queryParams = createQueryParams(page);
        const endpoint = constructEndpoint(queryParams);
        const res = await getData(endpoint);

        if (res?.status === 200) {
          handleResponse(res);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchProcessData(currentPage);
    // eslint-disable-next-line
  }, [currentPage, activeBtn, query, finalGrpQuery, finalOrgQuery]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const createQueryParams = () => {
    const queryParams = new URLSearchParams();
    const itemsPerPage = 20;

    if (activeBtn === "mapView") {
      queryParams.set("template", getTemplate("mapView"));
      queryParams.set("include", "child_processes,parent_processes,orgs");
      queryParams.set("use_pagination", "false");
    } else if (activeBtn === "cardView") {
      queryParams.set("template", getTemplate("cardView"));
      queryParams.set("include", "owners,orgs");
      queryParams.set("use_pagination", "true");
      queryParams.set("page", currentPage.toString());
      queryParams.set("per_page", itemsPerPage.toString());
    } else {
      queryParams.set("template", getTemplate());
      queryParams.set("include", "owners,orgs");
      queryParams.set("use_pagination", "true");
      queryParams.set("page", currentPage.toString());
    }

    addOptionalParams(queryParams);

    return queryParams;
  };

  const getTemplate = (view) => {
    if (query || finalGrpQuery?.length > 0 || finalOrgQuery?.length > 0) {
      return "process_search";
    }
    return view === "mapView" ? "process_map_view" : "process_card_view";
  };

  const addOptionalParams = (queryParams) => {
    if (finalGrpQuery?.length > 0) {
      queryParams.set("group", finalGrpQuery[0]?.id.toString());
    }
    if (finalOrgQuery?.length > 0) {
      queryParams.set("org_id", finalOrgQuery[0]?.id.toString());
    }
    if (query) {
      queryParams.set("search", query);
    }
  };

  const constructEndpoint = (queryParams) => {
    const baseUrl = getHostUrl();
    const path =
      query || finalGrpQuery?.length > 0 || finalOrgQuery?.length > 0
        ? "process/search"
        : "process";
    return `${baseUrl}${path}?${queryParams.toString()}`;
  };

  const setUsers = (list) => {
    let userList = [];
    // eslint-disable-next-line
    list.map((e) => {
      let newElem = included?.filter(
        (item) =>
          item.type === "owners" && item.id.toString() === e.id.toString()
      )[0];
      userList.push(newElem);
    });
    return userList;
  };
  const setOrgs = (list) => {
    return list
      .map((e) =>
        included?.find(
          (item) =>
            item.type === "orgs" && item.id.toString() === e.id.toString()
        )
      )
      .filter(Boolean);
  };

  const getOrgTitles = (orgIds) => {
    return orgIds.map((orgId) => {
      const org = included.find((orgs) => orgs.id === orgId);
      return {
        id: org?.attributes?.["org-id"],
        title: org?.attributes?.["org-master"]?.data?.attributes?.["org-title"],
      };
    });
  };

  const moreOrgs = (orgIds) => {
    return orgIds.map((org) => ({
      id: org?.attributes?.["org-id"],
      title: org?.attributes?.["org-master"]?.data?.attributes?.["org-title"],
    }));
  };

  const handleResponse = (res) => {
    setProcessDetails(res.data.data);
    setIncluded(res.data.included);

    if (activeBtn !== "mapView") {
      setTotalPage(res.data.meta["page-count"]);
      setCurrentPage(res.data.meta.page);
    }
  };

  const handleProcessClick = (processId) => {
    const selectedProcess = processDetails.find((p) => p.id === processId);
    setActivePro(selectedProcess);
  };

  const handleButtonClick = (buttonId) => {
    setActiveBtn(buttonId);
    navigate(`/landscape/process?id=0&view=${buttonId}`);
  };

  const handleEmployeeModalClick = async (owner) => {
    const empList = setUsers(owner.relationships.owners.data);
    setUsersData(empList);
    setShowEmpModal(true);
  };
  const handleOrgModalClick = (orgList) => {
    const data = setOrgs(orgList.relationships.orgs.data);
    setShowOrgModal(true);
    const orgTitles = moreOrgs(data);
    setOrgValues(orgTitles);
  };

  const handleGroupSearch = (selectedGroups) => {
    setGroupQuery(selectedGroups);
  };

  const handleOrgSearch = async (query) => {
    const param = `${getHostUrl()}organization/search?search=${query}&org_chart_flag=${true}&per_page=20`;
    const res = await getData(param);
    setOrgList(res?.data?.data);
  };

  const handleCancel = () => {
    setDropdownShow(false);
  };

  const handleApply = () => {
    setFinalOrgQuery(orgQuery);
    setFinalGrpQuery(groupQuery);
    setDropdownShow(false);
    setCurrentPage(1);
  };
  const handleInputChange = (e) => {
    setCurrentPage(1);
    if (e.target.value) {
      setQuery(e.target.value);
    } else {
      setQuery("");
    }
  };

  const renderButton = (buttonId, label) => (
    <Button
      className={`form-select-btn d-flex flex-row justify-content-center ${
        activeBtn === buttonId ? "form-select-activeBtn" : ""
      }`}
      style={{ width: "50%" }}
      onClick={() => handleButtonClick(buttonId)}>
      <div>{label}</div>
      <div className="mx-2">
        <ImageSvgs
          name={activeBtn === buttonId ? buttonId : `${buttonId}Disable`}
        />
      </div>
    </Button>
  );

  return (
    <div className="process-user-page">
      <div className="process-content">
        <Container fluid className="mx-0 me-0 main-process-container">
          <Row>
            <Col xs={6} xl={6} className="d-flex justify-content-start w-50">
              <ButtonGroup className="my-2 w-50">
                {renderButton("cardView", "Card View")}
                {renderButton("mapView", "Map View")}
              </ButtonGroup>
            </Col>
            <Col xs={6} xl={6} className="d-flex justify-content-end w-50">
              <div className="d-flex pt-1">
                {adminCheck && adminCheck === "true" ? (
                  <Nav.Link
                    href="/manage_process?page=addProcess"
                    className="my-auto">
                    <Button
                      // disabled={allLoading}
                      onClick={() => {
                        sessionStorage.setItem("processFlag", "true");
                      }}
                      className="app-tertiary-btn">
                      Add Process
                    </Button>
                  </Nav.Link>
                ) : null}
                <div
                  className="app-primary-input-field pt-1 ms-3"
                  style={{ width: "15rem" }}>
                  <input
                    type="search"
                    id="message"
                    value={query}
                    placeholder="Search Process..."
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <ProcessFilterDropdown
                dropdownShow={dropdownShow}
                setDropdownShow={setDropdownShow}
                groupList={groupList}
                orgList={orgList}
                groupQuery={groupQuery}
                orgQuery={orgQuery}
                handleGroupSearch={handleGroupSearch}
                handleOrgSearch={handleOrgSearch}
                handleCancel={handleCancel}
                handleApply={handleApply}
                setOrgQuery={setOrgQuery}
              />
            </Col>
          </Row>
        </Container>
        <div className="my-2 grid-container">
          {!loading ? (
            <ProcessViewContent
              activeView={activeBtn}
              processDetails={processDetails}
              activePro={activePro}
              getOrgTitles={getOrgTitles}
              handleProcessData={handleProcessClick}
              navigate={navigate}
              included={included}
              groupList={groupList}
              currentPage={currentPage}
              handleEmployeeModalClick={handleEmployeeModalClick}
              handleOrgModalClick={handleOrgModalClick}
              totalPage={totalPage}
              onPageChange={handlePageChange}
            />
          ) : (
            <div style={{ maxWidth: "100%", height: "47rem" }}>
              <Loader />
            </div>
          )}
        </div>

        {showEmpModal === true ? (
          <UserModal
            title={"Process Owners and Deputies"}
            listdata={usersData}
            show={showEmpModal}
            onHide={() => setShowEmpModal(false)}
          />
        ) : (
          ""
        )}
        {showOrgModal === true ? (
          <ProcessOrgDetailModal
            show={showOrgModal}
            title={"List of Org Codes"}
            onHide={() => setShowOrgModal(false)}
            orgdata={orgValues}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
