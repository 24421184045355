import React, { useState } from "react";
import GenericCard from "./../../Cards/GenericCard";
import ImageSvgs from "./../../ImageSvgs";
import CustomTooltip from "../../CommonComponents/CustomTooltip";
import Avatar from "react-avatar";
import { getBaseUrl } from "../../../config/environment";
import ProcessDetailsPage from "./ProcessDetailPage";

const ProcessRender = ({
  processDetails,
  activePro,
  getOrgTitles,
  handleProcessData,
  navigate,
  activeBtn,
  handleEmployeeModalClick,
  handleOrgModalClick,
  included,
}) => {
  const [showDetail, setShowDetail] = useState(false);

  const handleProcessClick = (processId) => {
    handleProcessData(processId);
    navigate(`/landscape/process?process_id=${processId}&view=${activeBtn}`, {
      state: { prevPath: window.location.href },
    });
    setShowDetail(true);
  };

  const handleClose = () => {
    setShowDetail(false);
  };
  return (
    <>
      <div className="grid-container">
        {processDetails?.length > 0 ? (
          <div className="w-100 d-flex flex-wrap flex-row">
            {processDetails.map((process) => {
              const isActive =
                process.id?.toString() === activePro?.id?.toString();
              const orgIds = process.relationships.orgs.data.map(
                (org) => org.id
              );
              const orgTitles = getOrgTitles(orgIds);

              const links = [
                {
                  href: `/landscape/organization?id=${orgTitles[0]?.id}`,
                  text: orgTitles.length > 0 ? orgTitles[0]?.title : "N/A",
                },
              ];

              const linkSectionMoreButton = (
                <button
                  className="more-user-btn"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleOrgModalClick(process);
                  }}>
                  {process.relationships.orgs.data.length > 1 ? (
                    `+ ${process.relationships.orgs.data.length - 1} more`
                  ) : (
                    <b></b>
                  )}
                </button>
              );
              const renderOwners = () => {
                const owners = process?.relationships?.owners?.data || [];
                return (
                  <>
                    {owners.slice(0, 3).map((elem, index) => {
                      const owner = included?.find(
                        (item) =>
                          item.type === "owners" &&
                          item.id.toString() === elem.id.toString()
                      );

                      const employee =
                        owner?.attributes?.employee?.data?.attributes;
                      const employeeId = owner?.attributes?.["employee-id"];
                      const profilePicture = employee?.["profile-picture"];
                      const preferredName = employee?.["preferred-name"];
                      const orgTitle =
                        employee?.["employee-org-master"]?.data?.attributes[
                          "org-title"
                        ];

                      const handleProfileClick = (e) => {
                        e.stopPropagation();
                        navigate(`/employee_relation?id=${employeeId}`);
                      };

                      return (
                        <span
                          key={index}
                          className="me-1"
                          style={{ cursor: "pointer" }}
                          onClick={handleProfileClick}>
                          {profilePicture ? (
                            <CustomTooltip
                              data={owner}
                              name={preferredName}
                              orgCode={orgTitle}>
                              <img
                                className="my-auto"
                                src={`${getBaseUrl()}${profilePicture}`}
                                style={{
                                  height: "2rem",
                                  width: "2rem",
                                  border: "1.5px solid black",
                                  borderRadius: "7rem",
                                  objectFit: "contain",
                                }}
                                alt="User Img"
                              />
                            </CustomTooltip>
                          ) : (
                            <CustomTooltip
                              data={owner}
                              name={preferredName}
                              orgCode={orgTitle}>
                              <Avatar
                                name={preferredName}
                                size="30"
                                round={true}
                              />
                            </CustomTooltip>
                          )}
                        </span>
                      );
                    })}
                    {owners.length > 3 && (
                      <button
                        className="more-user-btn"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEmployeeModalClick(process);
                        }}>
                        + {owners.length - 3} more
                      </button>
                    )}
                  </>
                );
              };

              return (
                <GenericCard
                  key={process.id}
                  id={process.id.toString()}
                  isActive={isActive}
                  header={process.attributes.title}
                  description={process.attributes.desc}
                  links={links}
                  linkSectionMoreButton={linkSectionMoreButton}
                  onClick={() => handleProcessClick(process.id)}
                  overlayTriggers={{
                    header: process.attributes.title,
                    description: process.attributes.desc,
                  }}
                  customClass="process-cards grid-item"
                  footer={renderOwners()}
                />
              );
            })}
          </div>
        ) : (
          <div
            className="d-flex justify-content-center"
            style={{ paddingTop: "7rem" }}>
            <ImageSvgs name="no_data" />
          </div>
        )}
      </div>
      {showDetail && (
        <ProcessDetailsPage
          hide={handleClose}
          show={true}
          activeData={true}
          activeBtn={true}
          deleted={false}
          setIsDeleted={true}
          allLoading={true}
        />
      )}
    </>
  );
};

export default ProcessRender;
