import React from "react";
import PropTypes from "prop-types";

const CustomBadge = ({ title, color, textColor, className }) => {
  const badgeStyle = {
    backgroundColor: color || "#000",
    color: textColor || "#fff",
    padding: "0.5em 0.5em",
    borderRadius: "0.25rem",
    fontSize: "0.75rem",
    display: "inline-block",
    fontWeight: "700",
    lineHeight: "1",
    verticalAlign: "middle",
  };

  return (
    <span className={`${className}`} style={badgeStyle}>
      {title}
    </span>
  );
};

CustomBadge.propTypes = {
  title: PropTypes.string.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string,
  className: PropTypes.string,
};

CustomBadge.defaultProps = {
  color: "#000",
  textColor: "#fff",
  className: "",
};

export default CustomBadge;
