import React from "react";
import { Dropdown, Button } from "react-bootstrap";
import { MultipleSelectDropdown } from "../../CommonComponents/MultipleSelect";
import { Typeahead } from "react-bootstrap-typeahead";
import ImageSvgs from "../../ImageSvgs";

const ProcessFilterDropdown = ({
  groupList,
  groupQuery,
  handleGroupSearch,
  orgList,
  orgQuery,
  handleOrgSearch,
  handleCancel,
  handleApply,
  dropdownShow,
  setDropdownShow,
  setOrgQuery,
}) => {
  return (
    <Dropdown
      show={dropdownShow}
      onSelect={() => setDropdownShow(true)}
      className="custom-dropdown-main">
      <Dropdown.Toggle
        onClick={() => setDropdownShow(true)}
        id="dropdown-basic"
        className="dropdown-btn pe-0">
        <ImageSvgs name="filterLogo" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="w-100 custom-dropdown">
        <div className="dropdown-title">Advanced Filters</div>

        <div className="pt-2 pb-2">
          <div className="pb-1 pt-1">Groups</div>
          <div className="app-primary-dropdown">
            <MultipleSelectDropdown
              multi={false}
              color="#8A00E5"
              placeholder="Search by Groups"
              className="dropdown-select mb-2"
              options={
                groupList?.length > 0 &&
                [...groupList]
                  .sort(
                    (a, b) =>
                      a.attributes["title"].split(" ").length -
                      b.attributes["title"].split(" ").length
                  )
                  ?.map((grp) => ({
                    type: "process-groups",
                    name: grp.attributes["title"],
                    id: grp.id.toString(),
                  }))
              }
              onChange={handleGroupSearch}
              values={groupQuery}
            />
          </div>
        </div>

        <div className="pt-2 pb-2">
          <div className="pb-1 pt-1">Org Code</div>
          <div className="app-primary-dropdown mb-3">
            <Typeahead
              labelKey="name"
              filterBy={["name"]}
              placeholder="Search by OrgCode"
              id="typeAhead"
              highlightOnlyResult={true}
              clearButton={true}
              onChange={(selected) => {
                if (selected?.length > 0) {
                  setOrgQuery(selected);
                }
              }}
              onInputChange={(text) => {
                handleOrgSearch(text);
                setOrgQuery(text);
              }}
              options={
                orgQuery?.length < 4
                  ? []
                  : orgList &&
                    orgList.map((item) => ({
                      type: "organization",
                      name: item.attributes["org-title"],
                      id: item.id,
                    }))
              }
            />
          </div>
        </div>

        <div className="d-flex justify-content-end font-weight-bold pt-4">
          <Button
            className="app-secondary-btn ms-3 my-auto"
            onClick={handleCancel}>
            Cancel
          </Button>
          <Button className="app-primary-btn ms-3" onClick={handleApply}>
            Apply
          </Button>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProcessFilterDropdown;
