import { Button } from "react-bootstrap";
// import ProcessUpdatedPage from "../components/ProcessAdminComponent/ProcessUserPage/ProcessUpdatedPage";
import ProcessView from "../components/ProcessAdminComponent/ProcessUserPage/ProcessView";
import { useNavigate, useLocation } from "react-router-dom";

export default function ProcessPage() {
  const navigate = useNavigate();
  const { state } = useLocation();

  return (
    <div>
      <div className="main-internal-page mx-auto mt-4 mb-5">
        <div className="page-header d-flex flex-row justify-content-between border-bottom">
          <div>
            <h2 className="fw-bold">Processes </h2>
          </div>
          <div>
            <Button
              className="app-tertiary-btn ms-3 mt-1"
              onClick={() => {
                if (state?.prevPath) {
                  window.location.assign(state?.prevPath);
                } else {
                  navigate("/");
                }
              }}>
              Close page X
            </Button>
          </div>
        </div>

        <div className="mt-3">
          {/* <ProcessUpdatedPage /> */}
          <ProcessView />
        </div>
      </div>
    </div>
  );
}
