import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import classNames from "classnames";

import "./GenericCard.scss";
import "./../ProcessAdminComponent/ProcessAdminPage.scss";

const GenericCard = ({
  id,
  isActive,
  header,
  description,
  links = [],
  linkSectionMoreButton,
  onClick,
  onLinkClick,
  customClass = "",
  overlayTriggers = [],
  footer,
}) => {
  return (
    <div
      key={id}
      className={classNames("generic-card", customClass, {
        highlighted: isActive,
        "not-highlighted": !isActive,
      })}
      onClick={(e) => {
        onClick(e);
      }}>
      <div className="header-section">
        {overlayTriggers.header ? (
          <OverlayTrigger
            placement="bottom"
            overlay={
              <Tooltip id={`tooltip-header`}>{overlayTriggers.header}</Tooltip>
            }>
            <div className="header-content">{header}</div>
          </OverlayTrigger>
        ) : (
          <div className="header-content">{header}</div>
        )}
      </div>
      <div className="links-section">
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.href}
            className="link-item"
            onClick={(e) => {
              e.stopPropagation();
              if (onLinkClick) onLinkClick(link, e);
            }}>
            {link.text}
          </Link>
        ))}{" "}
        {linkSectionMoreButton}
      </div>

      <div className="description-section">
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip id={`tooltip-title`}>{description}</Tooltip>}>
          <div className="process-desc">{description}</div>
        </OverlayTrigger>
      </div>

      <div className="d-flex flex-row justify-content-between">
        <div>
          <span>{footer}</span>
        </div>
      </div>
    </div>
  );
};

GenericCard.propTypes = {
  id: PropTypes.string.isRequired,
  isActive: PropTypes.bool,
  header: PropTypes.node.isRequired,
  description: PropTypes.node,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
    })
  ),
  linkSectionMoreButton: PropTypes.node,
  onClick: PropTypes.func,
  onLinkClick: PropTypes.func,
  customClass: PropTypes.string,
  overlayTriggers: PropTypes.shape({
    header: PropTypes.node,
    description: PropTypes.node,
  }),
  additionalHandlers: PropTypes.object,
};

GenericCard.defaultProps = {
  isActive: false,
  description: null,
  links: [],
  linkSectionMoreButton: null,
  onClick: () => {},
  onLinkClick: () => {},
  customClass: "",
  overlayTriggers: {},
  additionalHandlers: {},
};

export default GenericCard;
