import React from "react";
import ProcessRender from "./ProcessRender";
import ProcessNetworkGraph from "./ProcessNetworkGraph";
import ResponsivePagination from "react-responsive-pagination";

function ProcessViewContent({
  activeView,
  processDetails,
  activePro,
  getOrgTitles,
  handleProcessData,
  navigate,
  included,
  groupList,
  currentPage,
  handleEmployeeModalClick,
  handleOrgModalClick,
  totalPage,
  onPageChange,
}) {
  return (
    <>
      <div className="view-content">
        {activeView === "cardView" && (
          <ProcessRender
            processDetails={processDetails}
            activePro={activePro}
            getOrgTitles={getOrgTitles}
            handleProcessData={handleProcessData}
            navigate={navigate}
            activeBtn={activeView}
            handleEmployeeModalClick={handleEmployeeModalClick}
            handleOrgModalClick={handleOrgModalClick}
            included={included}
          />
        )}

        {activeView === "mapView" && (
          <div className="map-view-content">
            <ProcessNetworkGraph
              data={processDetails}
              included={included}
              groupList={groupList}
              view={activeView}
            />
          </div>
        )}
      </div>
      <div
        className="w-100 mt-3  pb-2  d-flex justify-content-end"
        style={{ paddingRight: "2rem" }}>
        {activeView === "cardView" ? (
          <ResponsivePagination
            current={currentPage}
            total={totalPage}
            onPageChange={onPageChange}
          />
        ) : null}
      </div>
    </>
  );
}

export default ProcessViewContent;
