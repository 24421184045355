import * as React from "react";
import { useState, useEffect } from "react";

import PropTypes from "prop-types";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { useTreeItem } from "@mui/lab/TreeItem";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import { IoIosArrowDown } from "react-icons/io";
import { getData } from "../../services/apiService";
import { getBaseUrl, getHostUrl } from "../../config/environment";
import Avatar from "react-avatar";
import { Row, Col } from "react-bootstrap";
import { nextArrow } from "../../utils/iconUtils";
import ImageSvgs from "../ImageSvgs";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { Button } from "react-bootstrap";
import { chunk } from "lodash";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router";
import Loader from "../Loader/Loader";
import { UserModal } from "./UserModal";
import TopicModal from "./TopicModal";
import MapComp from "../LocationMap/MapComp";
import { Menu, MenuItem } from "react-bootstrap-typeahead";
import "react-bootstrap-typeahead/css/Typeahead.css";

const downArrow = (
  <span>
    <IoIosArrowDown style={{ color: "#8A00E5" }} />
  </span>
);

const CustomContent = React.forwardRef(function CustomContent(props, ref) {
  const {
    classes,
    className,
    label,
    nodeId,
    icon: iconProp,
    expansionIcon,
    displayIcon,
  } = props;

  const {
    disabled,
    expanded,
    selected,
    focused,
    handleExpansion,
    handleSelection,
    preventSelection,
  } = useTreeItem(nodeId);

  const icon = iconProp || expansionIcon || displayIcon;

  const handleMouseDown = (event) => {
    preventSelection(event);
  };

  const handleExpansionClick = (event) => {
    handleExpansion(event);
  };

  const handleSelectionClick = (event) => {
    handleSelection(event);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div
      className={clsx(className, classes.root, {
        [classes.expanded]: expanded,
        [classes.selected]: selected,
        [classes.focused]: focused,
        [classes.disabled]: disabled,
      })}
      onMouseDown={handleMouseDown}
      ref={ref}>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div onClick={handleExpansionClick} className={classes.iconContainer}>
        {icon}
      </div>
      <Typography
        onClick={handleSelectionClick}
        component="div"
        className={classes.label}>
        {label}
      </Typography>
    </div>
  );
});

CustomContent.propTypes = {
  classes: PropTypes.object.isRequired,

  className: PropTypes.string,

  displayIcon: PropTypes.node,

  expansionIcon: PropTypes.node,

  icon: PropTypes.node,

  label: PropTypes.node,

  nodeId: PropTypes.string.isRequired,
};

function CustomTreeItem(props) {
  return <TreeItem ContentComponent={CustomContent} {...props} />;
}

export default function IconExpansionTreeView({ mainOrgList }) {
  var Typeahead = require("react-bootstrap-typeahead").Typeahead;
  const navigate = useNavigate();

  const url = new URL(window.location.href);
  const tempOrg = url.searchParams.get("id");

  const [empKey, setEmpKey] = useState("0");
  const [currentOrg, setCurrentOrg] = useState();
  const [activeOrg, setActiveOrg] = useState();
  const [empList, setEmpList] = useState();
  const [btnList, setBtnList] = useState([]);
  const [proCons, setProCons] = useState([]);
  const [topicCons, setTopicCons] = useState([]);
  const [empOptions, setEmpOptions] = useState([]);
  // eslint-disable-next-line
  const [selectedEmp, setSelectedEmp] = useState([]);
  const [query, setQuery] = useState("");
  const [orgQuery, setOrgQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [key, setKey] = useState("1");
  const [connectionData, setConnectionData] = useState([]);
  const [included, setIncluded] = useState([]);
  const [usersData, setUsersData] = useState([]);
  const [show, setShow] = useState(false);
  const [showTopicModal, setShowTopicModal] = useState(false);
  const [activeTopicData, setActiveTopicData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [locationList, setLocationList] = useState();
  const [orgOptions, setOrgOptions] = useState([]);
  const userOrgId = JSON.parse(sessionStorage.getItem("userData")).orgId;
  const orgFlag = JSON.parse(sessionStorage.getItem("userData")).orgFlag;

  useEffect(() => {
    (async () => {
      const res = await getData(
        `${getBaseUrl()}/files/locations/locationList.json`
      );
      await setLocationList(res.data);

      if (url.searchParams.get("id").toString() === "0" && orgFlag === true) {
        addQueryParam("id", userOrgId);
        handleOnLoadOrg(userOrgId);
      } else if (url.searchParams.get("id").toString() === "0" && !orgFlag) {
        const res = await getData(
          `${getHostUrl()}organization/search?search=SE CFO&org_chart_flag=true`
        );
        addQueryParam("id", res.data.data[0].id);
        handleOnLoadOrg(res.data.data[0].id);
      } else {
        handleOnLoadOrg(tempOrg);
      }
    })();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (included) {
      const matched = getProcessMembers(proCons, included);
      setConnectionData(matched);
      const topicData = getTopicsMembers(topicCons, included);
      setTopicData(topicData);
    }

    // eslint-disable-next-line
  }, [proCons, topicCons, key]);

  const addQueryParam = (key, value) => {
    const url = new URL(window.location.href);
    url.searchParams.set(key, value);
    window.history.pushState({}, "", url.toString());
  };

  async function SetOrgList(tempId) {
    addQueryParam("id", tempId);
    setEmpList(null);
    setEmpKey("0");
    let employees = [];
    let proData = [];
    let topicData = [];

    const res = await getData(
      `${getHostUrl()}organization/${tempId}?include=filter_org_employee`
    );
    setIncluded(res?.data.included);

    res?.data.included.map((elem) => {
      proData.push(...elem.attributes.processes.data);
      return null;
    });

    setProCons([
      ...new Map(
        proData
          .sort((a, b) =>
            a.attributes["title"].localeCompare(b.attributes["title"])
          )
          .map((item) => [item.id, item])
      ).values(),
    ]);

    res?.data.included.map((elem) => {
      topicData.push(...elem.attributes.topics.data);
      return null;
    });
    setTopicCons([
      ...new Map(
        topicData
          .sort((a, b) =>
            a.attributes["topics-name"].localeCompare(
              b.attributes["topics-name"]
            )
          )
          .map((item) => [item.id, item])
      ).values(),
    ]);

    let tempChildList =
      res?.data.data.attributes["parent-org"].data.length > 0
        ? res?.data.data.attributes["parent-org"].data.sort((a, b) =>
            a.attributes["org-title"].localeCompare(b.attributes["org-title"])
          )
        : [];

    res?.data.data.relationships["filter-org-employee"].data.map((elem) => {
      let newElem = res?.data.included?.filter(
        (emp) =>
          emp.type.toString() === "employee" &&
          emp.id.toString() === elem.id.toString()
      )[0];
      employees.push(newElem);
      return null;
    });

    let sortedList = employees.sort((a, b) =>
      a.attributes["f-name"].localeCompare(b.attributes["f-name"])
    );

    let foundIdx = sortedList?.findIndex(
      (el) =>
        el.attributes["preferred-name"] ===
        res?.data.data.attributes["org-head-data"]?.data.attributes[
          "preferred-name"
        ]
    );

    if (foundIdx > 0) {
      setEmpList([
        sortedList[foundIdx],
        ...sortedList?.slice(0, foundIdx),
        ...sortedList?.slice(foundIdx + 1),
      ]);
    } else {
      setEmpList(sortedList);
    }

    if (
      btnList?.filter((btn) => btn.orgData.id.toString() === tempId.toString())
        .length <= 0 &&
      tempChildList.length > 0
    ) {
      let newBtn = {
        id: btnList?.length + 1,
        orgData: {
          id: res?.data.data.id,
          org_title: res?.data.data.attributes["org-title"],
        },
      };
      setBtnList((btnList) => [...btnList, newBtn]);
    }

    if (tempChildList?.length > 0) {
      setCurrentOrg({
        id: tempId,
        attributes: res?.data.data.attributes,
        relationships: res?.data.data.relationships,
        children: tempChildList,
        included: res?.data.included,
      });
      setActiveOrg({
        id: tempId,
        attributes: res?.data.data.attributes,
        relationships: res?.data.data.relationships,
        children: tempChildList,
        included: res?.data.included,
      });
    } else {
      setActiveOrg({
        id: tempId,
        attributes: res?.data.data.attributes,
        relationships: res?.data.data.relationships,
        children: tempChildList,
        included: res?.data.included,
      });
    }
    return employees.sort((a, b) =>
      a.attributes["f-name"].localeCompare(b.attributes["f-name"])
    );
  }

  async function SetEmpData(emp) {
    let proData = [];
    let topicData = [];
    if (empKey === "0") {
      setEmpKey(emp);
      setProCons(
        emp.attributes.processes.data.sort((a, b) =>
          a.attributes["title"].localeCompare(b.attributes["title"])
        )
      );
      setTopicCons(
        emp.attributes.topics.data.sort((a, b) =>
          a.attributes["topics-name"].localeCompare(b.attributes["topics-name"])
        )
      );
    } else if (empKey?.id.toString() === emp?.id.toString()) {
      setEmpKey("0");
      activeOrg?.included.map((elem) => {
        proData.push(...elem.attributes.processes.data);
        return null;
      });

      setProCons([
        ...new Map(
          proData
            .sort((a, b) =>
              a.attributes["title"].localeCompare(b.attributes["title"])
            )
            .map((item) => [item.id, item])
        ).values(),
      ]);

      activeOrg?.included.map((elem) => {
        topicData.push(...elem.attributes.topics.data);
        return null;
      });
      setTopicCons([
        ...new Map(
          topicData
            .sort((a, b) =>
              a.attributes["topics-name"].localeCompare(
                b.attributes["topics-name"]
              )
            )
            .map((item) => [item.id, item])
        ).values(),
      ]);
    } else {
      setEmpKey(emp);
      setProCons(
        emp.attributes.processes.data.sort((a, b) =>
          a.attributes["title"].localeCompare(b.attributes["title"])
        )
      );
      setTopicCons(
        emp.attributes.topics.data.sort((a, b) =>
          a.attributes["topics-name"].localeCompare(b.attributes["topics-name"])
        )
      );
    }
  }

  const getMemberByProcessId = (process, employees) => {
    return employees.filter((employee) =>
      employee.attributes.processes.data.some(
        (empProcess) => empProcess.id === process.id
      )
    );
  };

  const getMemberByTopicId = (topic, employees) => {
    return employees?.filter((employee) =>
      employee?.attributes.topics.data.some(
        (empProcess) => empProcess.id === topic.id
      )
    );
  };
  const getProcessMembers = (processes, employees) => {
    return processes?.map((process) => ({
      process: process,
      employees: getMemberByProcessId(process, employees),
    }));
  };

  const getTopicsMembers = (topics, employees) => {
    return topics?.map((topic) => ({
      topic: topic,
      employees: getMemberByTopicId(topic, employees),
    }));
  };

  const removeBtn = async (btn) => {
    await SetOrgList(btn.orgData.id);
    setBtnList(btnList.slice(0, btn.id));
  };

  const handleOnLoadOrg = async (tempId) => {
    let employees = [];
    let proData = [];
    let topicData = [];

    addQueryParam("id", tempId);

    const res = await getData(
      `${getHostUrl()}organization/${tempId}?include=filter_org_employee`
    );
    setIncluded(res?.data.included);

    res?.data.included.map((elem) => {
      proData.push(...elem.attributes.processes.data);
      return null;
    });

    setProCons([
      ...new Map(
        proData
          .sort((a, b) =>
            a.attributes["title"].localeCompare(b.attributes["title"])
          )
          .map((item) => [item.id, item])
      ).values(),
    ]);

    res?.data.included.map((elem) => {
      topicData.push(...elem.attributes.topics.data);
      return null;
    });
    setTopicCons([
      ...new Map(
        topicData
          .sort((a, b) =>
            a.attributes["topics-name"].localeCompare(
              b.attributes["topics-name"]
            )
          )
          .map((item) => [item.id, item])
      ).values(),
    ]);

    let tempChildList =
      res?.data.data.attributes["parent-org"].data.length > 0
        ? res?.data.data.attributes["parent-org"].data.sort((a, b) =>
            a.attributes["org-title"].localeCompare(b.attributes["org-title"])
          )
        : [];

    res?.data.data.relationships["filter-org-employee"].data.map((elem) => {
      let newElem = res?.data.included?.filter(
        (emp) =>
          emp.type.toString() === "employee" &&
          emp.id.toString() === elem.id.toString()
      )[0];
      employees.push(newElem);
      return null;
    });

    let sortedList = employees.sort((a, b) =>
      a.attributes["f-name"].localeCompare(b.attributes["f-name"])
    );

    let foundIdx = sortedList?.findIndex(
      (el) =>
        el.attributes["preferred-name"] ===
        res?.data.data.attributes["org-head-data"]?.data.attributes[
          "preferred-name"
        ]
    );

    if (foundIdx > 0) {
      setEmpList([
        sortedList[foundIdx],
        ...sortedList?.slice(0, foundIdx),
        ...sortedList?.slice(foundIdx + 1),
      ]);
    } else {
      setEmpList(sortedList);
    }

    await setBtnList([]);

    if (tempChildList?.length > 0) {
      let tempList =
        res?.data.data.attributes.path.length > 0
          ? res?.data.data.attributes.path.reverse().map((elem, index) => {
              return {
                id: index + 1,
                orgData: {
                  id: elem?.id,
                  org_title: elem?.org_title,
                },
              };
            })
          : [];

      tempList.push({
        id: tempList.length + 1,
        orgData: {
          id: tempId,
          org_title: res?.data.data.attributes["org-title"],
        },
      });

      setBtnList((btnList) => [...btnList, ...tempList]);
      setCurrentOrg({
        id: tempId,
        attributes: res?.data.data.attributes,
        relationships: res?.data.data.relationships,
        children: tempChildList,
        included: res?.data.included,
      });
      setActiveOrg({
        id: tempId,
        attributes: res?.data.data.attributes,
        relationships: res?.data.data.relationships,
        children: tempChildList,
        included: res?.data.included,
      });
    } else {
      let tempList =
        res?.data.data.attributes.path.length > 0
          ? res?.data.data.attributes.path.reverse().map((elem, index) => {
              return {
                id: index + 1,
                orgData: {
                  id: elem?.id,
                  org_title: elem?.org_title,
                },
              };
            })
          : [];

      const tempRes = await getData(
        `${getHostUrl()}organization/${
          tempList?.slice(-1)[0]?.orgData.id
        }?include=filter_org_employee`
      );

      let tempChildList =
        res?.data.data.attributes["parent-org"].data.length > 0
          ? res?.data.data.attributes["parent-org"].data.sort((a, b) =>
              a.attributes["org-title"].localeCompare(b.attributes["org-title"])
            )
          : [];

      setBtnList((btnList) => [...btnList, ...tempList]);
      setCurrentOrg({
        id: tempList?.slice(-1)[0]?.orgData.id.toString(),
        attributes: tempRes?.data.data.attributes,
        relationships: tempRes?.data.data.relationships,
        children:
          tempRes?.data.data.attributes["parent-org"].data.length > 0
            ? tempRes?.data.data.attributes["parent-org"].data.sort((a, b) =>
                a.attributes["org-title"].localeCompare(
                  b.attributes["org-title"]
                )
              )
            : [],
        included: tempRes?.data.included,
      });
      setActiveOrg({
        id: tempId,
        attributes: res?.data.data.attributes,
        relationships: res?.data.data.relationships,
        children: tempChildList,
        included: res?.data.included,
      });
    }
  };

  const handleEmpOrgChange = async (value) => {
    let tempId = value[0].attributes["org-id"];
    let employees = [];
    let proData = [];
    let topicData = [];

    const res = await getData(
      `${getHostUrl()}organization/${tempId}?include=filter_org_employee`
    );
    setIncluded(res?.data.included);

    addQueryParam("id", tempId);

    res?.data.included.map((elem) => {
      proData.push(...elem.attributes.processes.data);
      return null;
    });

    setProCons(
      chunk(
        [
          ...new Map(
            proData
              .sort((a, b) =>
                a.attributes["title"].localeCompare(b.attributes["title"])
              )
              .map((item) => [item.id, item])
          ).values(),
        ],
        5
      )
    );

    res?.data.included.map((elem) => {
      topicData.push(...elem.attributes.topics.data);
      return null;
    });
    setTopicCons(
      chunk(
        [
          ...new Map(
            topicData
              .sort((a, b) =>
                a.attributes["topics-name"].localeCompare(
                  b.attributes["topics-name"]
                )
              )
              .map((item) => [item.id, item])
          ).values(),
        ],
        5
      )
    );

    let tempChildList =
      res?.data.data.attributes["parent-org"].data.length > 0
        ? res?.data.data.attributes["parent-org"].data.sort((a, b) =>
            a.attributes["org-title"].localeCompare(b.attributes["org-title"])
          )
        : [];

    res?.data.data.relationships["filter-org-employee"].data.map((elem) => {
      let newElem = res?.data.included?.filter(
        (emp) =>
          emp.type.toString() === "employee" &&
          emp.id.toString() === elem.id.toString()
      )[0];
      employees.push(newElem);
      return null;
    });

    let sortedList = employees.sort((a, b) =>
      a.attributes["f-name"].localeCompare(b.attributes["f-name"])
    );

    await SetEmpData(
      sortedList?.filter(
        (item) => item.id.toString() === value[0].id.toString()
      )[0]
    );

    let foundIdx = sortedList?.findIndex(
      (el) =>
        el.attributes["preferred-name"] ===
        res?.data.data.attributes["org-head-data"]?.data.attributes[
          "preferred-name"
        ]
    );

    if (foundIdx > 0) {
      setEmpList([
        sortedList[foundIdx],
        ...sortedList?.slice(0, foundIdx),
        ...sortedList?.slice(foundIdx + 1),
      ]);
    } else {
      setEmpList(sortedList);
    }

    await setBtnList([]);

    if (tempChildList?.length > 0) {
      let tempList =
        res?.data.data.attributes.path.length > 0
          ? res?.data.data.attributes.path.reverse().map((elem, index) => {
              return {
                id: index + 1,
                orgData: {
                  id: elem?.id,
                  org_title: elem?.org_title,
                },
              };
            })
          : [];

      tempList.push({
        id: tempList.length + 1,
        orgData: {
          id: tempId,
          org_title: res?.data.data.attributes["org-title"],
        },
      });

      setBtnList((btnList) => [...btnList, ...tempList]);
      setCurrentOrg({
        id: tempId,
        attributes: res?.data.data.attributes,
        relationships: res?.data.data.relationships,
        children: tempChildList,
        included: res?.data.included,
      });
      setActiveOrg({
        id: tempId,
        attributes: res?.data.data.attributes,
        relationships: res?.data.data.relationships,
        children: tempChildList,
        included: res?.data.included,
      });
    } else {
      let tempList =
        res?.data.data.attributes.path.length > 0
          ? res?.data.data.attributes.path.reverse().map((elem, index) => {
              return {
                id: index + 1,
                orgData: {
                  id: elem?.id,
                  org_title: elem?.org_title,
                },
              };
            })
          : [];

      const tempRes = await getData(
        `${getHostUrl()}organization/${
          tempList?.slice(-1)[0]?.orgData.id
        }?include=filter_org_employee`
      );

      let tempChildList =
        res?.data.data.attributes["parent-org"].data.length > 0
          ? res?.data.data.attributes["parent-org"].data.sort((a, b) =>
              a.attributes["org-title"].localeCompare(b.attributes["org-title"])
            )
          : [];

      setBtnList((btnList) => [...btnList, ...tempList]);
      setCurrentOrg({
        id: tempList?.slice(-1)[0]?.orgData.id.toString(),
        attributes: tempRes?.data.data.attributes,
        relationships: tempRes?.data.data.relationships,
        children:
          tempRes?.data.data.attributes["parent-org"].data.length > 0
            ? tempRes?.data.data.attributes["parent-org"].data.sort((a, b) =>
                a.attributes["org-title"].localeCompare(
                  b.attributes["org-title"]
                )
              )
            : [],
        included: tempRes?.data.included,
      });
      setActiveOrg({
        id: tempId,
        attributes: res?.data.data.attributes,
        relationships: res?.data.data.relationships,
        children: tempChildList,
        included: res?.data.included,
      });
    }
  };

  let debounceTimer;
  // const orgDataCache = {};

  const handleQueryChange = async (text) => {
    await setLoading(true);
    clearTimeout(debounceTimer);

    debounceTimer = setTimeout(async () => {
      if (text.length === 0 || text.length < 4) {
        setEmpOptions([]);
        setSelectedEmp([]);
      } else {
        try {
          const param = `${getHostUrl()}employee/search?search=${text}&org_chart_flag=true&user_type=I&is_gid=${true}&per_page=30`;
          const res = await getData(param);
          setEmpOptions(res?.data.data);
        } catch (error) {
          console.error("Error occurred:", error);
        } finally {
          await setLoading(false);
        }
      }
    }, 500);
  };

  const handleOrgSearch = async (query) => {
    const param = `${getHostUrl()}organization/search?search=${query}&org_chart_flag=${true}&per_page=20`;
    const res = await getData(param);

    setOrgOptions(res?.data?.data);
  };

  return (
    <>
      {currentOrg ? (
        <div className="d-flex">
          <TreeView
            aria-label="icon expansion"
            defaultCollapseIcon={downArrow}
            defaultExpandIcon={nextArrow}
            expanded={[currentOrg?.id]}
            selected={`${activeOrg?.id}`}
            sx={{
              flexGrow: 1,
              width: "25%",
              maxWidth: "25%",
              overflowY: "auto",
              borderRight: "1px solid #c0c0c0",
              paddingRight: "1rem",
            }}>
            <div className="app-primary-dropdown mb-3">
              <Typeahead
                labelKey="name"
                filterBy={["name", "email", "gid"]}
                placeholder="Search by Name, Mail or GID"
                id="typeAhead"
                highlightOnlyResult={true}
                clearButton={true}
                onChange={async (selected) => {
                  await handleEmpOrgChange(selected);
                }}
                disabled={orgQuery?.length > 0 ? true : false}
                onInputChange={(text) => {
                  setQuery(text);
                  handleQueryChange(text);
                }}
                options={
                  query?.length < 4
                    ? []
                    : empOptions &&
                      [
                        ...new Map(
                          empOptions?.map((item) => [item.id, item])
                        ).values(),
                      ].map((item) => ({
                        type: "employee",
                        name: item.attributes["preferred-name"],
                        email: item.attributes["email-id"],
                        gid: item.attributes["gid"],
                        attributes: item.attributes,
                        id: item.id,
                      }))
                }
                renderMenu={(results, menuProps) => (
                  <Menu {...menuProps}>
                    {query?.length < 4 ? (
                      <MenuItem disabled>Keep typing...</MenuItem>
                    ) : query?.length >= 4 && loading ? (
                      <MenuItem disabled>Loading...</MenuItem>
                    ) : query?.length >= 4 &&
                      !loading &&
                      results.length === 0 ? (
                      <p className="text-muted mb-0 ms-2 mt-1 mb-1">
                        No results found!{" "}
                        <a
                          style={{ color: "#8a00e5" }}
                          href="https://cosmos.siemens-energy.cloud/"
                          target="_blank"
                          rel="noreferrer">
                          Visit Cosmos
                        </a>
                      </p>
                    ) : (
                      results.map((option, index) => (
                        <MenuItem key={index} option={option} position={index}>
                          {option.name}
                        </MenuItem>
                      ))
                    )}
                  </Menu>
                )}></Typeahead>
            </div>

            <div className="app-primary-dropdown mb-3">
              <Typeahead
                labelKey="name"
                filterBy={["name"]}
                placeholder="Search by OrgCode"
                id="typeAhead"
                highlightOnlyResult={true}
                clearButton={true}
                onChange={async (selected) => {
                  if (selected?.length > 0) {
                    handleOnLoadOrg(selected[0]?.id);
                  }
                }}
                disabled={query?.length > 0 ? true : false}
                onInputChange={(text) => {
                  handleOrgSearch(text);
                  setOrgQuery(text);
                }}
                options={
                  orgQuery?.length < 4
                    ? []
                    : orgOptions &&
                      orgOptions.map((item) => ({
                        type: "organization",
                        name: item.attributes["org-title"],
                        id: item.id,
                      }))
                }></Typeahead>
            </div>
            <span className="breadcrumb-comp">
              {btnList?.map((btn, index) => {
                return (
                  <>
                    <button
                      key={index}
                      className="breadcrumb-btn border-0 bg-transparent fw-bold"
                      style={{ color: "#8A00E5", fontSize: "15px" }}
                      value={btn.level}
                      onClick={(e) => {
                        removeBtn(btn);
                      }}>
                      {btn?.orgData?.org_title}
                    </button>
                    <span style={{ color: "#8A00E5" }}>{nextArrow}</span>
                  </>
                );
              })}
            </span>

            <CustomTreeItem
              nodeId={currentOrg?.id}
              label={
                <div
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    currentOrg?.attributes.description
                      ? `${currentOrg?.attributes["org-title"]} (${currentOrg?.attributes.description})`
                      : `${currentOrg?.attributes["org-title"]}`
                  }
                  className="d-flex org-list-comp py-2"
                  onClick={() => {
                    SetOrgList(currentOrg?.id);
                  }}>
                  <span className="my-auto">
                    {!currentOrg?.attributes["org-head-data"]?.data.attributes[
                      "profile-picture"
                    ] ||
                    currentOrg?.attributes["org-head-data"]?.data.attributes[
                      "profile-picture"
                    ].includes("none") ? (
                      <Avatar
                        name={
                          currentOrg?.attributes["org-head-data"]?.data
                            .attributes["preferred-name"]
                        }
                        size="46"
                        round={true}
                      />
                    ) : (
                      <img
                        className="mt-1 user-profile-img"
                        style={{ height: "3rem", width: "3rem" }}
                        src={`${getBaseUrl()}${
                          currentOrg?.attributes["org-head-data"]?.data
                            .attributes["profile-picture"]
                        }`}
                        alt="User"
                      />
                    )}
                  </span>
                  <div className="my-auto ms-2 org-list-title">
                    {currentOrg.attributes["is-acting-head"] === true ? (
                      <h5 className="mb-0" style={{ fontSize: "18px" }}>
                        {currentOrg?.attributes["org-head-data"]?.data
                          .attributes["preferred-name"]
                          ? "act." +
                            " " +
                            currentOrg?.attributes["org-head-data"]?.data
                              .attributes["preferred-name"]
                          : "Org Head"}
                      </h5>
                    ) : (
                      <h5 className="mb-0" style={{ fontSize: "18px" }}>
                        {currentOrg?.attributes["org-head-data"]?.data
                          .attributes["preferred-name"]
                          ? currentOrg?.attributes["org-head-data"]?.data
                              .attributes["preferred-name"]
                          : "Org Head"}
                      </h5>
                    )}

                    <p className="d-flex org-list-title mb-0">
                      <h6
                        className="mb-0 text-muted"
                        style={{
                          color: "rgb(138, 0, 229)",
                          marginLeft: "1px",
                        }}>
                        {currentOrg?.attributes.description ? (
                          currentOrg?.attributes.description
                        ) : (
                          <b>-</b>
                        )}
                      </h6>
                    </p>
                    <h6 className="mb-0" style={{ color: "rgb(138, 0, 229)" }}>
                      {currentOrg?.attributes["org-title"]}
                    </h6>
                  </div>
                </div>
              }>
              {currentOrg?.children
                ?.sort((a, b) =>
                  a.attributes["org-title"].localeCompare(
                    b.attributes["org-title"]
                  )
                )
                .map((elem, index) => {
                  let headData = elem?.attributes["org-head-data"]?.data;

                  return (
                    <CustomTreeItem
                      key={index}
                      nodeId={elem?.id}
                      label={
                        <div
                          data-toggle="tooltip"
                          data-placement="top"
                          title={
                            elem.attributes.description
                              ? `${elem?.attributes["org-title"]} (${elem.attributes.description})`
                              : `${elem?.attributes["org-title"]}`
                          }
                          className="d-flex py-1"
                          style={{
                            borderLeft:
                              currentOrg?.id.toString() === elem.id.toString()
                                ? "2px solid black"
                                : "white",
                          }}
                          onClick={() => {
                            SetOrgList(elem?.id);
                          }}>
                          {elem?.children?.length > 0 ? (
                            <span
                              className="my-auto me-1"
                              style={{ fontSize: "19px" }}>
                              {nextArrow}
                            </span>
                          ) : (
                            <span></span>
                          )}
                          <span className="my-auto">
                            {!headData?.attributes["profile-picture"] ||
                            headData?.attributes["profile-picture"]
                              .toLowerCase()
                              .includes("none") ? (
                              <Avatar
                                name={
                                  headData?.attributes["preferred-name"]
                                    ? headData?.attributes["preferred-name"]
                                    : elem?.attributes["org-title"]
                                }
                                size="46"
                                round={true}
                              />
                            ) : (
                              <img
                                className="mt-1 user-profile-img"
                                style={{ height: "3rem", width: "3rem" }}
                                src={`${getBaseUrl()}${
                                  headData?.attributes["profile-picture"]
                                }`}
                                alt="User"
                              />
                            )}
                          </span>
                          <div className="my-auto ms-2 org-list-title">
                            {elem.attributes["is-acting-head"] === true ? (
                              <h5 className="mb-0" style={{ fontSize: "18px" }}>
                                {headData?.attributes["preferred-name"]
                                  ? "act." +
                                    headData?.attributes["preferred-name"]
                                  : "Org Head"}
                              </h5>
                            ) : (
                              <h5 className="mb-0" style={{ fontSize: "18px" }}>
                                {headData?.attributes["preferred-name"]
                                  ? headData?.attributes["preferred-name"]
                                  : "Org Head"}
                              </h5>
                            )}

                            <div className="d-flex org-list-title mb-0">
                              <h6
                                className="mb-0 text-muted"
                                style={{
                                  color: "rgb(138, 0, 229)",
                                  marginLeft: "2px",
                                }}>
                                {elem?.attributes.description ? (
                                  elem?.attributes.description
                                ) : (
                                  <b>-</b>
                                )}
                              </h6>
                            </div>
                            <h6
                              className="mb-0 ms-1"
                              style={{ color: "rgb(138, 0, 229)" }}>
                              {elem?.attributes["org-title"]}
                            </h6>
                          </div>
                        </div>
                      }
                    />
                  );
                })}
            </CustomTreeItem>
          </TreeView>
          <div className="w-75">
            {empList ? (
              <>
                <div className="ms-3 w-100">
                  <div className="border-bottom">
                    <p className="fw-bold mb-2" style={{ fontSize: "17px" }}>
                      Members ({empList.length})
                    </p>
                    <div className="w-100">
                      {chunk(empList, 4)?.map((record, i) => {
                        return (
                          <Row key={i} xs={1} md={6} className="g-2 w-100 mb-3">
                            {record?.map((emp, id) => {
                              return (
                                <Col
                                  key={id}
                                  className={
                                    empKey === "0"
                                      ? "me-2 org-emp-comp"
                                      : empKey?.id.toString() ===
                                        emp?.id.toString()
                                      ? "me-2 org-emp-comp org-act-comp"
                                      : "me-2 org-emp-comp"
                                  }
                                  onClick={() => {
                                    SetEmpData(emp);
                                  }}>
                                  <div
                                    className="d-flex"
                                    style={{ cursor: "pointer" }}>
                                    <span className="mt-1 ">
                                      {emp.attributes["profile-picture"] ? (
                                        <img
                                          className="user-profile-img"
                                          src={`${getBaseUrl()}${
                                            emp.attributes["profile-picture"]
                                          }`}
                                          alt="User"
                                        />
                                      ) : (
                                        <Avatar
                                          name={
                                            emp?.attributes["preferred-name"]
                                          }
                                          size="40"
                                          round={true}
                                        />
                                      )}
                                    </span>
                                    <span className="w-75">
                                      <p
                                        className="mb-0 ms-2 org-emp-title"
                                        style={{ fontWeight: "700" }}>
                                        {emp?.attributes["f-name"]}{" "}
                                        {emp?.attributes["l-name"]}
                                      </p>
                                      <span className="ms-2">
                                        <span
                                          className="me-2"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Copy email"
                                          onClick={() => {
                                            navigator.clipboard.writeText(
                                              emp.attributes["email-id"]
                                            );
                                          }}>
                                          <ImageSvgs name="expertCopy" />
                                        </span>
                                        <a
                                          href={`mailto:${emp.attributes["email-id"]}`}
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Mail to">
                                          <span className="me-2">
                                            <ImageSvgs name="expertMail" />
                                          </span>
                                        </a>
                                        <span
                                          className="me-2"
                                          onClick={() => {
                                            navigate(
                                              `/employee_relation?id=${emp.id}`
                                            );
                                          }}>
                                          <ImageSvgs name="relationIcon" />
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                </Col>
                              );
                            })}
                          </Row>
                        );
                      })}
                    </div>
                  </div>
                </div>
                <div className="ms-3 mt-2 org-connections-section">
                  {empKey.toString() === "0" ? (
                    <div className="tabs-section border-bottom tab-section">
                      <ButtonGroup className="me-2" aria-label="First group">
                        <Button
                          className={
                            key === "1"
                              ? "tools-tab-btn actTab"
                              : "tools-tab-btn"
                          }
                          onClick={() => {
                            setKey("1");
                          }}>
                          Topics
                        </Button>
                        <Button
                          className={
                            key === "2"
                              ? "tools-tab-btn actTab mx-2"
                              : "tools-tab-btn mx-2"
                          }
                          onClick={() => {
                            setKey("2");
                          }}>
                          Processes
                        </Button>
                        <Button
                          className={
                            key === "3"
                              ? "tools-tab-btn actTab mx-2"
                              : "tools-tab-btn mx-2"
                          }
                          onClick={() => {
                            setKey("3");
                          }}>
                          Locations
                        </Button>
                      </ButtonGroup>
                    </div>
                  ) : (
                    <>
                      <div className="tabs-section border-bottom tab-section">
                        <ButtonGroup className="me-2" aria-label="First group">
                          <Button
                            className={
                              key === "1"
                                ? "tools-tab-btn actTab"
                                : "tools-tab-btn"
                            }
                            onClick={() => {
                              setKey("1");
                            }}>
                            Topics
                          </Button>
                          <Button
                            className={
                              key === "2"
                                ? "tools-tab-btn actTab mx-2"
                                : "tools-tab-btn mx-2"
                            }
                            onClick={() => {
                              setKey("2");
                            }}>
                            Processes
                          </Button>
                          <Button
                            className={
                              key === "3"
                                ? "tools-tab-btn actTab mx-2"
                                : "tools-tab-btn mx-2"
                            }
                            onClick={() => {
                              setKey("3");
                            }}>
                            Locations
                          </Button>
                        </ButtonGroup>
                      </div>
                    </>
                  )}

                  {key === "3" ? (
                    <MapComp
                      empKey={empKey && empKey}
                      empList={empList && empList}
                      locationList={locationList && locationList}
                    />
                  ) : (
                    <div className="p-3 org-table">
                      <div className="table-respponsive w-100">
                        <Table className="main-table">
                          <thead className="user-table-head">
                            {key === "1" ? (
                              <>
                                {topicCons?.length > 0 ? (
                                  <tr>
                                    <th>Title </th>
                                    <th>Description</th>
                                    <th>Members </th>
                                  </tr>
                                ) : null}
                              </>
                            ) : key === "2" ? (
                              <>
                                {proCons?.length > 0 ? (
                                  <tr>
                                    <th>Title </th>
                                    <th>Description</th>
                                    <th>Members </th>
                                  </tr>
                                ) : null}
                              </>
                            ) : null}
                          </thead>

                          <tbody>
                            {key === "1" ? (
                              <>
                                {topicCons?.length > 0 ? (
                                  topicCons?.map((topics) => (
                                    <tr key={topics.id}>
                                      <td
                                        onClick={() => {
                                          setShowTopicModal(true);
                                          setActiveTopicData(topics);
                                        }}
                                        title={
                                          topics?.attributes?.["topics-name"]
                                        }>
                                        {topics?.attributes?.["topics-name"]}
                                      </td>
                                      <td
                                        title={
                                          topics.attributes[
                                            "topics-description"
                                          ]
                                        }>
                                        {
                                          topics.attributes[
                                            "topics-description"
                                          ]
                                        }
                                      </td>
                                      <td>
                                        {topicData?.length > 0 &&
                                          topicData
                                            .filter(
                                              (elem) =>
                                                elem?.topic?.id === topics.id
                                            )
                                            .map((elem) => (
                                              <div key={elem.id}>
                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                    paddingTop: 0,
                                                  }}>
                                                  <span>
                                                    {elem?.employees?.length >
                                                    0 ? (
                                                      <>
                                                        {elem?.employees
                                                          ?.slice(0, 3)
                                                          .map(
                                                            (elem, index) => {
                                                              return (
                                                                <span
                                                                  className="me-1"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    navigate(
                                                                      `/employee_relation?id=${elem?.id}`
                                                                    )
                                                                  }>
                                                                  {elem
                                                                    ?.attributes[
                                                                    "profile-picture"
                                                                  ] ? (
                                                                    <img
                                                                      className="my-auto"
                                                                      src={`${getBaseUrl()}${
                                                                        elem
                                                                          ?.attributes[
                                                                          "profile-picture"
                                                                        ]
                                                                      }`}
                                                                      style={{
                                                                        height:
                                                                          "2rem",
                                                                        width:
                                                                          "2rem",
                                                                        border:
                                                                          "1.5px solid black",
                                                                        borderRadius:
                                                                          "7rem",
                                                                        objectFit:
                                                                          "contain",
                                                                      }}
                                                                      alt="User Img"
                                                                    />
                                                                  ) : (
                                                                    <Avatar
                                                                      name={
                                                                        elem
                                                                          ?.attributes[
                                                                          "preferred-name"
                                                                        ]
                                                                      }
                                                                      size="30"
                                                                      round={
                                                                        true
                                                                      }
                                                                    />
                                                                  )}
                                                                </span>
                                                              );
                                                            }
                                                          )}
                                                        {elem?.employees
                                                          ?.length > 3 ? (
                                                          <>
                                                            <button
                                                              className="more-user-btn"
                                                              onClick={() => {
                                                                setUsersData(
                                                                  elem?.employees
                                                                );
                                                                setShow(true);
                                                              }}>
                                                              +{" "}
                                                              {elem?.employees
                                                                ?.length -
                                                                3}{" "}
                                                              more
                                                            </button>
                                                          </>
                                                        ) : null}
                                                      </>
                                                    ) : (
                                                      <b>-</b>
                                                    )}
                                                  </span>
                                                </td>
                                              </div>
                                            ))}
                                      </td>{" "}
                                    </tr>
                                  ))
                                ) : (
                                  <div className="d-flex justify-content-center p-2">
                                    <ImageSvgs name="no_data" />
                                  </div>
                                )}
                              </>
                            ) : key === "2" ? (
                              <>
                                {proCons?.length > 0 ? (
                                  proCons?.map((process) => (
                                    <tr key={process.id}>
                                      <td title={process.attributes.title}>
                                        <Link
                                          className="process-table-name"
                                          style={{ textDecoration: "none" }}
                                          to={`/landscape/process?process_id=${process.id}&view=cardView`}
                                          state={{
                                            prevPath: window.location.href,
                                          }}>
                                          {process.attributes.title}
                                        </Link>
                                      </td>
                                      <td title={process.attributes.desc}>
                                        {process.attributes.desc}
                                      </td>
                                      <td>
                                        {connectionData?.length > 0 &&
                                          connectionData
                                            .filter(
                                              (elem) =>
                                                elem?.process?.id === process.id
                                            )
                                            .map((elem) => (
                                              <div key={elem.id}>
                                                <td
                                                  style={{
                                                    textAlign: "left",
                                                    paddingTop: 0,
                                                  }}>
                                                  <span>
                                                    {elem?.employees?.length >
                                                    0 ? (
                                                      <>
                                                        {elem?.employees
                                                          ?.slice(0, 3)
                                                          .map(
                                                            (elem, index) => {
                                                              return (
                                                                <span
                                                                  className="me-1"
                                                                  style={{
                                                                    cursor:
                                                                      "pointer",
                                                                  }}
                                                                  onClick={() =>
                                                                    navigate(
                                                                      `/employee_relation?id=${elem?.id}`
                                                                    )
                                                                  }>
                                                                  {elem
                                                                    ?.attributes[
                                                                    "profile-picture"
                                                                  ] ? (
                                                                    <img
                                                                      className="my-auto"
                                                                      src={`${getBaseUrl()}${
                                                                        elem
                                                                          ?.attributes[
                                                                          "profile-picture"
                                                                        ]
                                                                      }`}
                                                                      style={{
                                                                        height:
                                                                          "2rem",
                                                                        width:
                                                                          "2rem",
                                                                        border:
                                                                          "1.5px solid black",
                                                                        borderRadius:
                                                                          "7rem",
                                                                        objectFit:
                                                                          "contain",
                                                                      }}
                                                                      alt="User Img"
                                                                    />
                                                                  ) : (
                                                                    <Avatar
                                                                      name={
                                                                        elem
                                                                          ?.attributes[
                                                                          "preferred-name"
                                                                        ]
                                                                      }
                                                                      size="30"
                                                                      round={
                                                                        true
                                                                      }
                                                                    />
                                                                  )}
                                                                </span>
                                                              );
                                                            }
                                                          )}
                                                        {elem?.employees
                                                          ?.length > 3 ? (
                                                          <>
                                                            <button
                                                              className="more-user-btn"
                                                              onClick={() => {
                                                                setUsersData(
                                                                  elem?.employees
                                                                );
                                                                setShow(true);
                                                              }}>
                                                              +{" "}
                                                              {elem?.employees
                                                                ?.length -
                                                                3}{" "}
                                                              more
                                                            </button>
                                                          </>
                                                        ) : null}
                                                      </>
                                                    ) : (
                                                      <b>-</b>
                                                    )}
                                                  </span>
                                                </td>
                                              </div>
                                            ))}
                                      </td>{" "}
                                    </tr>
                                  ))
                                ) : (
                                  <div className="d-flex justify-content-center p-2">
                                    <ImageSvgs name="no_data" />
                                  </div>
                                )}
                              </>
                            ) : (
                              <tr>
                                <td colSpan="3">Location</td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  )}

                  {show === true ? (
                    <UserModal
                      show={show}
                      usersData={usersData}
                      onHide={() => setShow(false)}
                    />
                  ) : null}

                  {showTopicModal === true ? (
                    <TopicModal
                      show={showTopicModal}
                      onHide={() => setShowTopicModal(false)}
                      data={activeTopicData}
                    />
                  ) : null}
                </div>
              </>
            ) : (
              <div style={{ maxWidth: "100%", height: "100vh" }}>
                <Loader />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div style={{ maxWidth: "100%", height: "100vh" }}>
          <Loader />
        </div>
      )}
    </>
  );
}
